.welcome {
    font-size: 35px;
    color: #6CA21F;
    font-weight: bold;
    text-align: center;
}

.subTitle {
    font-size: 20px;
    text-align: center;
}

.container {
    width: 100%;
    background-color: #F3F7FA;
    display: flex;
    justify-content: center;
    align-items: center;
}

.flexHorizontal {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
}

.flexColumn {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.block {
    display: flex;
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 50px;
    background-color: white;
    height: 220px;
    width: 400px;
    border-radius: 20px;
    border-width: 2px;
    border-color: rgba(0, 0, 0, 0.2);;
    border-style: solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
}

.block:hover {
    border-color: #6CA21F;
    color: #6CA21F;
}
.alreadyUse{
    display: flex;
    justify-content:center;
    margin-right: 30px;
    margin-left: 30px;
    margin-top: 50px;
    background-color: white;
    height: 448px;
    width: 688px;
    border-radius: 20px;
    border-width: 2px;
    border-color: white;
    border-style: solid;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}
.alreadyUse h1{
    size: 28px;
    font: "Raleway";
    color: #323929;
}
.alreadyUse p{
    color: #555555;
    line-height: 30px;
    font-size: 16px;
    text-align: center;
}
.alreadyUse span{
    color: #555555;
    line-height: 30px;
    font-size: 16px;
    text-align: center;
    margin-bottom: 32px;
}
.bottomText {
    font-size: 19px;
    font-weight: bold;
    opacity: 0.6;
    margin-left: 5px;
    text-align: center;
}

.footerDiv {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 40px;
    cursor: pointer;
    flex-direction: row;
}

.footerDiv:hover {
    border-color: #6CA21F;
    color: #6CA21F;
}

.blockText {
    font-size: 20px;
    font-weight: bold;
    opacity: 0.7;
}

@media screen and ( max-width: 1224px ) {
    .footerDiv {
        flex-direction: column;
        width: 90%;
    }
    .flexColumn {
        margin-bottom: 20px;
    }
}

@media screen and ( max-width: 320px ) {
    .flexColumn {
        margin-bottom: 20px;
        margin-right: 5px;
        margin-left: 5px;
    }
}