body {
  margin: 0;
  font-family: 'Raleway';
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

::-webkit-scrollbar {
  width: 5px;
}
  ::-webkit-scrollbar-track {
  background: #f1f1f1; 
}
  ::-webkit-scrollbar-thumb {
  background: #888; 
    border-radius:10px;
}
::-webkit-scrollbar-thumb:hover {
  
  background: #555; 
} 
