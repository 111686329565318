nav {
    height: 100px;
    background: white;
    display:flex;
    align-items:center;    /* vertically center */
    padding: 0 120px;
    box-shadow: 0 2px 30px -5px #d6d7d8;
    position: fixed;
    right: 0;
    left: 0;
    top: 0;
    z-index: 8;
    font-family: 'Raleway';
}

.button-connexion {
    padding: 15px 32px;;
    background: none;
    color: #6CA21F;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    margin-right: 30px;
    cursor: pointer;
    font-family: 'Raleway';
}

.button-connexion:hover {
    padding: 15px 32px;;
    background: #6CA21F;
    color: #fff;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: bold;
    margin-right: 30px;
    cursor: pointer;
}

.button-contact {
    padding: 15px 32px;;
    background: #6CA21F;
    color: white;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    cursor: pointer;
    font-family: 'Raleway';
}

.button-contact:hover {
    padding: 15px 32px;;
    background: #fff;
    color: #6CA21F;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: bold;
    cursor: pointer;
}

@media (max-width: 1224px) { 
    nav {
        height: 100px;
        background: white;
        display:flex;
        align-items:center;    /* vertically center */
        padding: 0 30px;
        box-shadow: 0 2px 30px -5px #d6d7d8;
        position: fixed;
        right: 0;
        left: 0;
        top: 0;
        z-index: 8;
        font-family: 'Raleway';
    }

    .button-connexion {
        font-size: 16px;
        padding: 15px 32px;;
        background: #6CA21F;
        color: #fff;
        border: 3px solid #6CA21F;
        transition: 0.5s;
        border-radius: 18px;
        margin-right: 0;
        font-weight: 700;
        cursor: pointer;
        font-family: 'Raleway';
    }
}