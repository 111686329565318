:root {
    --primary: #6CA21F;
}

p {
    font-family: 'Raleway';
}

.rightBoxWeb {
    background-color: white;
    width: 70%;
    height: 100vh;
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    overflow-y: auto;
}

.container2 {
    margin-top: 125px;
    margin-left: 75px;
    width: 80%;
    display: inline-block;
    text-align: left;
    flex-direction: column;
    background-color: white;
}

.titleDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
}

.titleText1 {
    color: var(--primary);
    font-size: 36px;
    font-weight: bold;
    text-align: left;
}

.titleText2 {
    color: black;
    font-size: 36px;
    font-weight: bold;
}

.subTitleText {
    font-size: 20px;
    text-align: left;
    margin-top: -10px;
    margin-right: 20px;
    font-family: 'Raleway';
    line-height: 1.5;
}

.sub-title-checks {
    font-size: 23px;
    text-align: left;
    margin-top: 50px;
    font-family: 'Raleway';
    font-weight: 700;
    color: #323929;
}

.card {
    display: flex;
    margin-top: 30px;
    width: 99%;
    height: auto;
    border-radius: 20px;
    border-width: 2px;
    border-color: rgba(0, 0, 0, 0.2);;
    border-style: solid;
    cursor: pointer;
    justify-content: flex-start;
    flex-direction: row;
    margin-left: 0px;
    align-items: center;
}

.card:hover {
    border-color: #6CA21F;
    color: #6CA21F;
}

.cardDropdown {
    margin-top: 30px;
    width: 99%;
    height: 100px;
    border-radius: 20px;
    border-width: 2px;
    height: auto;
    border-color: rgba(0, 0, 0, 0.2);;
    border-style: solid;
    cursor: pointer;
    margin-left: 0px;
}

.divRowDropdown {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.cardDropdown:hover {
    border-color: #6CA21F;
    color: #6CA21F;
}

.iconsCircle {
    margin-left: 20px;
}

.blockParagraph {
    margin-left: 20px;
    margin-right: 20px;
    text-align: left;
    color: black;
    font-size: 19px;
}

.dropdownParagraph {
    margin-right: 20px;
    text-align: left;
    color: #555555;
    line-height: 28px;
    font-size: 19px;
    /* font-weight: bold; */
}

.iconArrow {
    margin-right: 50px;
    opacity: 0.8;
}

.iconArrowMobile {
    all: none;
    display: none;
}

.dropdownDiv {
    color: black;
}

.divRowDropdownUpload {
    margin-left: 72px;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
}

.cardUpload {
    display: flex;
    margin-top: 10px;
    width: 50%;
    height: 100px;
    border-radius: 20px;
    border-width: 2px;
    border-color: rgba(0, 0, 0, 0.2);;
    border-style: solid;
    cursor: pointer;
    justify-content: flex-start;
    flex-direction: row;
    margin-left: 0px;
    align-items: center;
}

.cardUploadOnDrag {
    border-style: dashed;
    border-color: var(--primary);
}

.uploaded-files {
    background-color: #F6F6F9;
    height: 40px;
    margin-right: 4px;
    margin-left: 4px;
    margin-top: 4px;
    margin-bottom: 4px;
    border-radius: 6px;
    color: #555555;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
}

.uploaded-files-text {
    color: black;
    margin-right: 20px;
    margin-left: 20px;
    font-size: 17px;
    font-weight: 400;
}

.cardUpload:hover {
    border-color: var(--primary);
    color: #e7face;
}

.greenSanteColorText {
    color: var(--primary);
}

.paragraphUpload {
    z-index: 42;
    width: 100%;
    font-size: 18px;
    color: black;
    text-align: center;
}

.RowDiv {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 60%;
    margin-left: 10px;
}

.CheckParagraphStyle {
    font-size: 19px;
    margin-left: 15px;
    cursor: pointer;
}

.RowDivLinkMain {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 40px;
}

.RowDivLink {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-left: 10px;
}

.linkParagraphStyle {
    margin-left: 6px;
    color: black;
    font-size: 17px;
    text-decoration: none;
}

.linkParagraphStyle:hover {
    color: var(--primary);
}

.buttonDiv {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
}

.buttonSend {
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 18px;
    border-width: 3px;
    border-color: #9F9F9F;
    border-style: solid;
    align-items: center;
    text-align: center;
    background-color: #B0B0B0;
    cursor: pointer;
}

.buttonText {
    font-weight: 700;
    font-size: 17px;
    margin-right: 20px;
    margin-left: 20px;
    line-height: 24px;
    color: #EDEDED;
}

.iconsCheckBoxMesures {
    height: 34px;
    width: 34px;
    cursor: pointer;
}

.backButtonMobile {
    color: var(--primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    font-weight: bold;
    font-size: 19px;
    cursor: pointer;
    margin-top: -40px;
    margin-bottom: 20px;
}

.form-row-div {
    display: flex;
    justify-content: flex-start;
    flex-direction: row;
    margin-top: 20px;
}

.form-margin-style {
    margin-left: 20px;
    margin-right: 20px;
}

.form-margin-style-checks {
    margin-left: 7px;
    margin-top: 20px;
}

.div-arrow-mobile {
    width: 50px;
}

.linkIcons {
    width: 32px;
    height: 32px;
}

.button-return-green-sante-pop-up {
    border-radius: 20px;
    border-width: 2px;
    border-color: #6CA21F;
    border-style: solid;
    color: var(--primary);
    background-color: white;
    font-size: 19px;
    font-weight: 700;
    align-items: center;
    align-self: center;
    padding: 17px;
    cursor: pointer;
    margin-top: 20px;
    text-align: center;
}

.button-return-green-sante-pop-up:hover {
    background-color: var(--primary);
    color: white;
}

.card-pop-up {
    background-color: white;
    padding: 40px;
    border-radius: 40px;
    display: flex;
    align-items: center;
    flex-direction: column;
    max-width: 700px;
    box-shadow: 0px 0px 200px 1px rgba(0, 0, 0, 0.2);
}

.error-message-style {
    color: #f44336;
    margin-left: 14px;
    margin-right: 14px;
    font-size: 0.75rem;
    margin-top: 3px;
    line-height: 1.66;
    font-family: 'Raleway';
}

@media screen and ( max-width: 1224px ) {
    .rightBoxWeb {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .container2 {
        margin-left: 20px;
        margin-right: 20px;
        width: 90%;
        display: flex;
        text-align: left;
        flex-direction: column;
        justify-content: center;
        background-color: white;
    }
    .titleText1 {
        font-size: 22px;
    }
    .titleText2{
        font-size: 22px;
    }
    .subTitleText {
        font-size: 18px;
    }
    .linkParagraphStyle {
        font-size: 17px;
    }
    .dropdownParagraph {
        font-size: 17px;
    }
    .blockParagraph {
        font-size: 17px;
    }
    p {
        font-size: 17px;
    }
    .card {
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        align-items: flex-start;
    }
    .iconsCircle {
        margin-top: 20px;
    }
    .linkIcons {
        width: 22px;
        height: 22px;
    }
    .divRowDropdown {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .cardDropdown {
        height: auto;
    }
    .linkParagraphStyle {
        font-size: 17px;
    }
    .CheckParagraphStyle {
        font-size: 17px;
    }
    .iconArrow {
        all: none;
        display: none;
    }
    .iconArrowMobile {
        all: none;
        display: block;
        margin-top: 10px;
        align-items: flex-end;
        margin-right: 20px;
    }
    .div-arrow-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .divRowDropdownUpload {
        margin-left: 20px;
    }
    .paragraphUpload {
        margin-left: 0px;
        margin-right: 0px;
    }
    .cardUpload {
        width: 90%;
        height: 80px;
    }
    .RowDiv {
        width: 100%;
    }
    .RowDivLinkMain {
        flex-direction: column;
        align-items: flex-start;
    }
    .buttonDiv {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .form-row-div {
        flex-direction: column;
        width: 100%;
    }
    .form-margin-style {
        margin-top: 20px;
    }
    .buttonSend {
        width: 100%;
    }
    .card-pop-up {
        width: auto;
        margin-right: 20px;
        margin-left: 20px;
        font-size: 17px;
    }
    .button-return-green-sante-pop-up {
        font-size: 17px;
    }
}

@media screen and ( max-width: 380px ) {
    .card-pop-up {
        width:  71%;
        margin-right: 0px;
        margin-left: 10px;
    }
    .container2 {
        width: 87%;
    }
    .rightBoxWeb {
        width: 100%;
    }
}

@media screen and ( max-width: 320px ) {
    .card-pop-up {
        width:  70%;
        margin-right: 0px;
        margin-left: 7px;
    }
    .button-return-green-sante-pop-up {
        font-size: 14px;
    }
}