:root {
    --primary: #6CA21F;
}

.divTitleContainer {
    width: 100%;
    background-color: #F3F7FA;
    border-radius: 14px;
    border-style: solid;
    border-color: transparent;
}

.titleContainer {
    font-weight: 700;
    font-size: 19px;
    line-height: 24px;
    text-align: left;
    margin-left: 20px;
}

.variableText {
    font-size: 22px;
}

.cardDivers {
    margin-right: 20px;
    margin-left: 20px;
    border-radius: 26px;
    border-color: #EDEDED;
    border-width: 2px;
    border-style: solid;
    width: 240px;
    height: 104px;
    display: flex;
    justify-content: center;
    flex-direction: row;
    align-items: center;
}

.cardDiversDiv {
    width: 80%;
    margin-top: 20px;
    margin-bottom: 20px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.Pname {
    color:#555555;
    font-size: 23px;
    font-weight: 700;
}

.Pvalue {
    color:#6CA21F;
    font-size: 23px;
    font-weight: 700;
    margin-left: 10px;
}

.buttonAccess {
    margin-top: 50px;
    margin-bottom: 50px;
    border-radius: 18px;
    border-width: 3px;
    border-color: #6CA21F;
    border-style: solid;
    align-items: center;
    text-align: center;
    background-color: white;
    cursor: pointer;
}

.buttonTextccess {
    font-weight: 700;
    font-size: 17px;
    line-height: 24px;
    margin-right: 10px;
    margin-left: 10px;
    color: #6CA21F;
}

@media screen and ( max-width: 1224px ) {
    .cardDiversDiv {
        flex-direction: column;
        width: 100%;
    }
    .cardDivers {
        margin-top: 10px;
        margin-bottom: 10px;
    }
}