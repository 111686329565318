:root {
  --primary: #6CA21F;
}

.leftBoxWeb {
    background-color: #F3F7FA;
    width: 35%;
    height: 100vh;
    display: flex;
        justify-content: center;
    align-items: center;
    flex-direction: column;
}

.backButton {
    color: var(--primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    font-weight: bold;
    font-size: 19px;
    cursor: pointer;
    left: 40px;
    top: 125px;
}

.illustration {
    margin-top: 20vh;
    margin-right: 20px;
    margin-left: 20px;
}

@media screen and ( max-width: 1405px ) {
    .illustration {
        height: 550px;
    }
}
@media screen and ( max-width: 1450px ) {
    .illustration {
        height: 570px;
    }
}
@media screen and ( max-width: 1350px ) {
    .illustration {
        height: 500px;
    }
}