/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

p {
    font-size: 19px;
}

/* Firefox */
input[type=number] {
  -moz-appearance: textfield;
}

.rowCgu {
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 80%;
}

.centered-div {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: 20px;
    margin-right: 20px;
}

.button-submit {
    padding: 15px 0px;;
    background: none;
    color: #6CA21F;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    font-size: 14px;
    cursor: pointer;
    font-family: 'Raleway';
    float: right;
    width: 250px;
    height: 53px;
}

.button-submit-sc {
    padding: 15px 0px;;
    background: #6CA21F;
    color: #fff;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    cursor: pointer;
    font-family: 'Raleway';
    float: right;
    width: 100%;
    height: 53px;
    font-size: 17px;
}

.button-submit-sc-disabled {
    padding: 15px 0px;;
    background: #B0B0B0;
    color: white;
    border: 3px solid #9F9F9F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    cursor: not-allowed;
    font-family: 'Raleway';
    float: right;
    font-size: 17px;
    width: 100%;
    height: 53px;
}

.button-submit:hover {
    background: #6CA21F;
    color: #fff;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: bold;
    cursor: pointer;
}

.button-submit-legislation {
    padding: 15px 0px;;
    background: none;
    color: #6CA21F;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    cursor: pointer;
    font-family: 'Raleway';
    float: right;
    width: 270px;
    height: 53px;
}

.button-submit-legislation:hover {
    background: #6CA21F;
    color: #fff;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: bold;
    cursor: pointer;
}

.button-primary-disabled {
    padding: 15px 32px;;
    background: #B0B0B0;
    color: white;
    border: 3px solid #9F9F9F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    float: right;
    cursor: not-allowed;
    font-family: 'Raleway';
    width: 240px;
}

.button-primary {
    padding: 15px 32px;;
    background: #6CA21F;
    color: white;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    float: right;
    cursor: pointer;
    font-family: 'Raleway';
    width: 240px;
}

.button-primary:hover {
    padding: 15px 32px;;
    background: #fff;
    color: #6CA21F;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: bold;
    cursor: pointer;
}

.submit-loader {
    background-color: turquoise;
}

.button-return {
    padding: 15px 0;;
    background: none;
    color: #6CA21F;
    border: none;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    font-size: 17px;
    cursor: pointer;
    font-family: 'Raleway';
}

.button-return-sc {
    padding: 15px 0;;
    background: none;
    color: #6CA21F;
    border: none;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    cursor: pointer;
    font-size: 19px;
    font-family: 'Raleway';
}

.button-return:hover {
    padding: 15px 0;
    color: #91cf39;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: bold;
    cursor: pointer;
}

.icon-step {
    margin-right: 10px;
    font-size: 35px;
}

.icon-step-sc {
    font-size: 25px;
}

.icon-step-active {
    font-size: 32px;
}

.button-add-child {
    height: 40px;
    width: 100%;
    background-color: transparent;
    border: 1px solid #d1d1d1;
    border-radius: 5px;
}

.button-add-child:hover {
    height: 40px;
    width: 100%;
    background-color: #f1f1f1;
    border: 1px solid #d1d1d1;
    cursor: pointer;
    border-radius: 5px;
}

.upload {
    width: 100%;
    border: 3px solid #C2C2C1;
    border-radius: 25px; 
    text-align: center; 
    line-height: 0.5; 
    padding-top: 20px; 
    padding-bottom: 20px; 
    cursor: pointer;
    transition: 0.3s;
}

.upload-active {
    width: 100%;
    border: 3px dashed #6CA21F;
    border-radius: 25px; 
    text-align: center; 
    line-height: 0.5; 
    padding-top: 20px; 
    padding-bottom: 20px; 
    cursor: pointer;
}

.upload:hover {
    border: 3px solid #6CA21F;
    transition: 0.3s;
}

.inputfile {
	display: none;
}

.file-times {
    color: #A2A09F;
    margin-left: 5px;
    margin-bottom: -2px;
    cursor: pointer;
}

.file-times:hover {
    color: rgb(187, 19, 19);
    margin-left: 5px;
    cursor: pointer;
}

.error-message {
    color: red;
    font-size: 12px;
    margin-left: 30px;
}

.special-label {
    color: #999A99;
    font-weight: 100;
    margin-left: -15px;
}

.test-class:focus {
    border: 1px solid #6CA21F !important;
    box-shadow: 0 0 0 1px #6CA21F !important;
}

.contact-select{
    width : 80%;
    height : 5vh;
    border-radius: 10px;
    padding-left: 10px;
    font-size: 17px;
    font-family: 'Raleway';
}

.contact-select .default-value{
    color: #999A99;
}
.contact-select option{
    color : black;
    font-size: 17px;
    font-family: 'Raleway';
}

.contact-select:focus {
    border: 1px solid #6CA21F !important;
}