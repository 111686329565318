@import url("./styleRight.css");

@media screen and ( max-width: 1224px ) {
    .rightBoxWeb {
        width: 100%;
        display: flex;
        align-items: center;
    }
    .container2 {
        margin-left: 20px;
        margin-right: 20px;
        width: 90%;
        display: flex;
        text-align: left;
        flex-direction: column;
        justify-content: center;
        background-color: white;
    }
    .titleText1 {
        font-size: 22px;
    }
    .titleText2{
        font-size: 22px;
    }
    .subTitleText {
        font-size: 18px;
    }
    .linkParagraphStyle {
        font-size: 17px;
    }
    .dropdownParagraph {
        font-size: 17px;
    }
    .blockParagraph {
        font-size: 17px;
    }
    p {
        font-size: 17px;
    }
    .card {
        flex-direction: column;
        justify-content: flex-start;
        height: auto;
        align-items: flex-start;
    }
    .iconsCircle {
        margin-top: 20px;
    }
    .linkIcons {
        width: 22px;
        height: 22px;
    }
    .divRowDropdown {
        flex-direction: column;
        height: auto;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .cardDropdown {
        height: auto;
    }
    .linkParagraphStyle {
        font-size: 17px;
    }
    .CheckParagraphStyle {
        font-size: 17px;
    }
    .iconArrow {
        all: none;
        display: none;
    }
    .iconArrowMobile {
        all: none;
        display: block;
        margin-top: 10px;
        align-items: flex-end;
        margin-right: 20px;
    }
    .div-arrow-mobile {
        display: flex;
        flex-direction: row;
        width: 100%;
        justify-content: space-between;
        align-items: center;
    }
    .divRowDropdownUpload {
        margin-left: 20px;
    }
    .paragraphUpload {
        margin-left: 0px;
        margin-right: 0px;
    }
    .cardUpload {
        width: 90%;
        height: 80px;
    }
    .RowDiv {
        width: 100%;
    }
    .RowDivLinkMain {
        flex-direction: column;
        align-items: flex-start;
    }
    .buttonDiv {
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }
    .form-row-div {
        flex-direction: column;
        width: 100%;
    }
    .form-margin-style {
        margin-top: 20px;
    }
    .buttonSend {
        width: 100%;
    }
    .card-pop-up {
        width: auto;
        margin-right: 20px;
        margin-left: 20px;
        font-size: 17px;
    }
    .button-return-green-sante-pop-up {
        font-size: 17px;
    }
}

@media screen and ( max-width: 380px ) {
    .card-pop-up {
        width:  71%;
        margin-right: 0px;
        margin-left: 10px;
    }
    .container2 {
        width: 87%;
    }
    .rightBoxWeb {
        width: 100%;
    }
}

@media screen and ( max-width: 320px ) {
    .card-pop-up {
        width:  70%;
        margin-right: 0px;
        margin-left: 7px;
    }
    .button-return-green-sante-pop-up {
        font-size: 14px;
    }
}