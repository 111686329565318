.raleway {
    font-family: 'Raleway' !important;
}

.not-found {
    justify-content: center;
    align-items: center;
    display: flex;
    flex-direction: column;
    height: 100vh;
    margin-left: 20px;
    margin-right: 20px;
}

.text {
    margin: 5px;
    text-align: center;
}

.button-return-safe {
    padding: 15px 32px;;
    background: none;
    color: #6CA21F;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: 700;
    margin-top: 20px;
    cursor: pointer;
    font-family: 'Raleway';
}

.button-return-safe:hover {
    padding: 15px 32px;;
    background: #6CA21F;
    color: #fff;
    border: 3px solid #6CA21F;
    transition: 0.5s;
    border-radius: 18px;
    font-weight: bold;
    cursor: pointer;
}