:root {
  --primary: #6CA21F;
}

.leftBoxWeb {
    background-color: #F3F7FA;
    width: 35%;
    height: 100vh;
    display: flex;
        justify-content: center;
    align-items: center;
    flex-direction: column;
}

.backButton {
    color: var(--primary);
    display: flex;
    flex-direction: row;
    align-items: center;
    position: absolute;
    font-weight: bold;
    font-size: 19px;
    cursor: pointer;
    left: 40px;
    top: 125px;
}
